import _ from 'lodash';
import xxh from 'xxhashjs/lib';
import Template from '@components/bridge/bridge/immutable/template';
import { getAbsoluteUrl } from '@utils/filePath';
import md5 from 'md5';
import mergeDeep from '@utils/lodash/mergeDeep';
import eagerCloneDeep from '@utils/lodash/eagerCloneDeep';
import { v4 as uuidv4 } from 'uuid';
import getInStorage from '@utils/lodash/getInStorage';
import setInStorage from '@utils/lodash/setInStorage';
class StudioMiscellaneous {
  static chapterHash = (chapter, compo, ratio, theme) => {
    let chapterHash = {
      idx: chapter._idx,
      compoId: compo.id,
      ratio,
      theme,
      inputs: {}
    };
    _.each(_.get(chapter, 'texts'), text => {
      chapterHash.inputs[text._id] = text.rules;
    });
    _.each(_.get(chapter, 'files'), file => {
      chapterHash.inputs[file._id] = file.rules;
    });
    return xxh.h32(JSON.stringify(chapterHash), 0xabcd).toString(16);
  };
  static thumbnail = (sourceConfig, compo, chapter, themeIdx, ratioIdx, absolute = false) => {
    if (!compo || !chapter) return null;
    let ans = _.get(compo, `thumbnail_previews.${chapter._hash}.src`) || _.get(chapter, `_thumbnails.${ratioIdx}.${themeIdx}.src`) || _.get(chapter, `_thumbnails.0.${themeIdx}.src`) || _.get(chapter, `_thumbnails.${ratioIdx}.0.src`) || _.get(chapter, `_thumbnails.0.0.src`);
    if (!ans) {
      // If no thumbnail could have been found, fallback to the source (e.g. the admin has removed all the themes before previews)
      const sourceChapterThumbnails = Template.get(sourceConfig || {}, `.compositions..${compo._id}.chapters.${chapter._idx}._thumbnails`) || [];
      ans = _.get(sourceChapterThumbnails, `${ratioIdx}.${themeIdx}.src`) || _.get(sourceChapterThumbnails, `0.${themeIdx}.src`) || _.get(sourceChapterThumbnails, `${ratioIdx}.0.src`) || _.get(sourceChapterThumbnails, `0.0.src`);
    }
    if (absolute) ans = getAbsoluteUrl(ans);
    return ans;
  };
  static fontStoreKey = provider => {
    const value = typeof provider !== 'string' ? md5(`${_.get(provider, 'family')}+${_.get(provider, 'style')}+${_.get(provider, 'postscript_name') || _.get(provider, 'postscriptName')}`) : md5(provider);
    return value;
  };
  static defaultValue = (rules, forText = null) => {
    if (forText !== true && _.get(rules, 'default_value.src')) {
      return _.get(rules, 'default_value.src');
    }
    if (forText !== true && _.get(rules, 'default_value')) {
      return _.get(rules, 'default_value');
    }
    if (forText !== true && _.get(rules, 'default_value_by_variable.src')) {
      return _.get(rules, 'default_value_by_variable.src');
    }
    if (forText !== true && _.get(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    if (forText !== false && _.get(rules, 'default_value')) {
      return _.get(rules, 'default_value');
    }
    if (forText !== false && _.get(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    if (forText !== false && _.has(rules, 'default_value')) {
      return _.get(rules, 'default_value');
    }
    if (forText !== false && _.has(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    if (forText !== false && _.get(rules, 'type') === 'boolean') {
      return false;
    }
    if (forText !== false && _.get(rules, 'type') === 'select') {
      return _.get(rules, 'values.0.value');
    }
    return '';
  };
  static defaultValueByVariable = (rules, forText = null) => {
    if (forText !== true && _.get(rules, 'default_value_by_variable.src')) {
      return _.get(rules, 'default_value_by_variable.src');
    }
    if (forText !== true && _.get(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    if (forText !== false && _.get(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    if (forText !== false && _.has(rules, 'default_value_by_variable')) {
      return _.get(rules, 'default_value_by_variable');
    }
    return '';
  };
  static fontStore = (template, appuser, router) => {
    return memo(() => {
      let store = {};
      let priority = 0;
      priority++;
      let spaceStore = !router.route.includes('/back') && _.cloneDeep(this.props.workspace ? _.get(template, 'workspace.fontStore') : _.get(template, 'organization.fontStore'));
      if (_.isArray(spaceStore) && !spaceStore.length) spaceStore = {};
      if (spaceStore) {
        _.forOwn(spaceStore, font => {
          font._priority = priority;
        });
        store = {
          ...store,
          ...spaceStore
        };
      }
      priority++;
      let templateStore = !router.route.includes('/back') && _.cloneDeep(_.get(template, 'fontStore'));
      if (_.isArray(templateStore) && !templateStore.length) templateStore = {};
      if (templateStore) {
        _.forOwn(templateStore, font => {
          font._priority = priority;
        });
        store = {
          ...store,
          ...templateStore
        };
      }
      priority++;
      let userStore = _.cloneDeep(_.get(appuser, 'profile.fontStore'));
      if (_.isArray(userStore) && !userStore.length) userStore = {};
      if (userStore) {
        _.forOwn(userStore, font => {
          font._priority = priority;
        });
        store = {
          ...store,
          ...userStore
        };
      }
      return _.map(_.reverse(_.sortBy(_.values(store), '_priority')), font => {
        delete font._priority;
        return font;
      });
    }, [this._id, appuser, template]);
  };
  static isFontFine = (font, isStatic, expanded = true) => {
    return font && (!isStatic && expanded ? font.id && font.font && font.font.custom && font.font.custom.family && font.font.custom.style && (font.font.custom.postscript_name || font.font.custom.postscriptName) && font.font.custom.file : font.style && font.family && (font.postscript_name || font.postscriptName) && font.file);
  };

  /**
   * Returns the number of refreshing previews, and the changed config (string or object)
   * @param info
   * @param config
   * @returns {number|{count: number, config: string}}
   */
  static mergePreviewRefreshesIntoTemplateConfig = (info, config) => {
    if (!config) return 0;
    let configWasAString = false;
    if (_.isString(config)) {
      config = JSON.parse(config);
      configWasAString = true;
    }
    let countTable = {};
    _.each(_.get(config, 'compositions') || [], (_compo, _compoIdx) => {
      const _compoId = _compo._id;
      _.each(_.get(_compo, 'chapters') || [], (_chapter, _chapterIdx) => {
        _.forOwn(info, (item, videoId) => {
          const whitelist = _.get(item, 'whitelist');
          const blacklist = _.get(item, 'blacklist');
          const wishedThumbnailIdx = _.get(whitelist, `compositions.${_compoId}.${_chapterIdx}`);
          if ((whitelist === 'ALL' || wishedThumbnailIdx) && !_.has(blacklist, `compositions.${_compoId}.${_chapterIdx}`)) {
            const basePath = `compositions.${config._.ids_map.int[_compoId].idx}.chapters.${_chapterIdx}._preview_refresh`;
            _.set(config, `${basePath}._definite`, true);
            _.set(config, `${basePath}.video_ids.${item.ratioIdx}.${item.themeIdx}`, videoId);
            _.set(config, `${basePath}.wished_thumbnail_idx`, parseInt(wishedThumbnailIdx));
            countTable[basePath] = true;
          }
        });
      });
    });
    _.forOwn(info, (item, videoId) => {
      const whitelist = _.get(item, 'whitelist');
      const blacklist = _.get(item, 'blacklist');
      if ((whitelist === 'ALL' || _.get(whitelist, 'info')) && !_.has(blacklist, 'info')) {
        const basePath = 'info._preview_refresh';
        _.set(config, `${basePath}._definite`, true);
        _.set(config, `${basePath}.video_ids.${item.ratioIdx}.${item.themeIdx}`, videoId);
        countTable[basePath] = true;
      }
    });
    if (configWasAString) config = JSON.stringify(config);
    return {
      count: Object.keys(countTable).length,
      config
    };
  };
  static templateConfig = template => {
    let config = _.get(template, 'config') ? memo(() => JSON.parse(_.get(template, 'config') || '{}'), [_.get(template, 'id'), _.get(template, 'config')]) : null;
    const previewRefreshes = _.get(template, 'previewRefreshes');
    if (previewRefreshes && config) StudioMiscellaneous.mergePreviewRefreshesIntoTemplateConfig(previewRefreshes, config);
    return config;
  };
  static transformTemplateConfigIntoState = (state, props) => {
    const templateConfig = StudioMiscellaneous.templateConfig(props.template);
    const compos = _.filter(_.get(templateConfig, 'compositions') || [], compo => _.get(compo, 'rules.ignored') !== true);
    const templateData = JSON.parse(_.get(props, 'video.templateData') || '[]');
    const templatePrefill = mergeDeep(_.get(templateConfig, '_.datasets.prefill') || {}, _.get(templateConfig, '_.datasets.user_prefill') || {});
    const previewPrefill = _.get(templateConfig, '_.datasets.preview');
    const givenPrefill = JSON.parse(_.get(props, 'prefill.templateData') || null);
    const prefill = props.mode === 'preview' ? previewPrefill : givenPrefill || templatePrefill;
    const dataSupply = (props.video ? templateData : prefill) || {};
    const dataSupplyCompos = _.cloneDeep(_.get(dataSupply, 'compositions') || {});
    const themeIdx = state.forms.snippet.theme.variant.defaultIndex;
    const theme = state.forms.snippet.theme.variant.custom || _.get(templateConfig, `info.theme.variants.${themeIdx}.colors`) || null;
    const ratioIdx = state.forms.snippet.ratio.value;
    const ratio = _.get(templateConfig, `info.ratios.${ratioIdx}`) || null;

    // global compo
    if (_.get(templateConfig, 'info.globals.texts.length') || _.get(templateConfig, 'info.globals.files.length')) {
      compos.unshift({
        _id: 'global',
        _global: true,
        id: 'global',
        rules: _.get(templateConfig, 'info.globals.rules') || {},
        chapters: [{
          ...{
            texts: _.get(templateConfig, 'info.globals.texts') || []
          },
          ...{
            files: _.get(templateConfig, 'info.globals.files') || []
          }
        }]
      });
      if (_.isArray(dataSupplyCompos) && (_.get(dataSupply, 'info.globals.texts.length') || _.get(dataSupply, 'info.globals.files.length'))) {
        const dataSupplyGlobalCompo = {
          _id: 'global',
          id: 'global',
          ...(_.get(dataSupply, 'info.globals.texts.length') && {
            texts: _.get(dataSupply, 'info.globals.texts') || []
          }),
          ...(_.get(dataSupply, 'info.globals.files.length') && {
            files: _.get(dataSupply, 'info.globals.files') || []
          })
        };
        dataSupplyCompos.unshift(dataSupplyGlobalCompo);
      }
    }
    let flatAns = {};
    // dynamic zone downsizing
    if (props.mode !== 'config') {
      // after
      for (let _compoIdx = 0; _compoIdx < compos.length; _compoIdx++) {
        let _compo = compos[_compoIdx];
        if (_.get(_compo, '_deleted') && _.get(_compo, 'rules.dynamic_zone_start') && !_.get(_compo, 'rules.dynamic_zone_end')) {
          _.set(compos[_compoIdx + 1], 'rules.dynamic_zone_start', true);
          _.unset(compos[_compoIdx], 'rules.dynamic_zone_start');
          if (_.get(_compo, 'rules.max_dynamic_zone_quantity')) {
            _.set(compos[_compoIdx + 1], 'rules.max_dynamic_zone_quantity', _compo.rules.max_dynamic_zone_quantity);
            _.unset(compos[_compoIdx], 'rules.max_dynamic_zone_quantity');
          }
          if (_.get(_compo, 'rules.min_dynamic_zone_quantity')) {
            _.set(compos[_compoIdx + 1], 'rules.min_dynamic_zone_quantity', _compo.rules.min_dynamic_zone_quantity);
            _.unset(compos[_compoIdx], 'rules.min_dynamic_zone_quantity');
          }
        }
      }
      // before
      for (let _compoIdx = compos.length - 1; _compoIdx >= 0; _compoIdx--) {
        let _compo = compos[_compoIdx];
        if (_.get(_compo, '_deleted') && !_.get(_compo, 'rules.dynamic_zone_start') && _.get(_compo, 'rules.dynamic_zone_end')) {
          _.set(compos[_compoIdx - 1], 'rules.dynamic_zone_end', true);
          _.unset(compos[_compoIdx], 'rules.dynamic_zone_end');
        }
      }
    }
    // now, let's create the tree
    for (let _compoIdx in compos) {
      _compoIdx = parseInt(_compoIdx);
      let _compo = compos[_compoIdx];
      if (props.mode !== 'config' && _.get(_compo, '_deleted')) continue;
      if (_.get(_compo, 'alias')) continue;
      const domCompoId = `compo/${xxh.h32(`compo:${_compo._id}`, 0xabcd).toString(16)}`;
      let compo = {
        ...eagerCloneDeep(_compo),
        _idx: parseInt(_compoIdx),
        _dom_id: domCompoId,
        _prev: _compoIdx > 0 ? compos[_compoIdx - 1] : null,
        _next: _compoIdx < compos.length - 1 ? compos[_compoIdx + 1] : null
      };
      compo.chapters = {};
      for (let _chapterIdx in _compo.chapters) {
        const _chapter = _compo.chapters[_chapterIdx];
        const domChapterId = `chapter/${xxh.h32(`compo:${_compo._id}>chapter:${_chapterIdx}`, 0xabcd).toString(16)}`;
        let chapter = {
          ...eagerCloneDeep(_chapter),
          _dom_id: domChapterId,
          _idx: parseInt(_chapterIdx)
        };
        if (chapter.texts) {
          chapter.texts = {};
          for (let _text of _chapter.texts) {
            if (_.get(_text, 'alias')) continue;
            const domTextId = `text/${xxh.h32(`compo:${_compo._id}>input:${_text._id}`, 0xabcd).toString(16)}`;
            chapter.texts[domTextId] = {
              ...eagerCloneDeep(_text),
              _dom_id: domTextId
            };
          }
        }
        if (chapter.files) {
          chapter.files = {};
          for (let _file of _chapter.files) {
            if (_.get(_file, 'alias')) continue;
            const domFileId = `file/${xxh.h32(`compo:${_compo._id}>input:${_file._id}`, 0xabcd).toString(16)}`;
            chapter.files[domFileId] = {
              ...eagerCloneDeep(_file),
              _dom_id: domFileId
            };
          }
        }
        compo.chapters[domChapterId] = chapter;
      }
      flatAns[domCompoId] = compo;
    }
    let ans = {};
    let dynamicZoneBeingProcessed = null;
    let _idx = 0;
    _.forOwn(flatAns, (compo, domCompoId) => {
      if (_.get(compo, 'rules.dynamic_zone_start') === true) {
        _idx++;
        const domZoneId = `zone/${xxh.h32(`zone:${_idx}`, 0xabcd).toString(16)}`;
        ans[domZoneId] = {
          _idx: parseInt(_idx),
          // e.g. 1 for the second dynamic zone among three
          _dom_id: domZoneId,
          rules: {
            ...(!isNaN(parseInt(_.get(compo, 'rules.min_dynamic_zone_quantity'))) ? {
              min_dynamic_zone_quantity: parseInt(compo.rules.min_dynamic_zone_quantity)
            } : null),
            ...(!isNaN(parseInt(_.get(compo, 'rules.max_dynamic_zone_quantity'))) ? {
              max_dynamic_zone_quantity: parseInt(compo.rules.max_dynamic_zone_quantity)
            } : null)
          },
          available_compos: {
            [domCompoId]: compo
          },
          user_compos: {}
        };
        dynamicZoneBeingProcessed = domZoneId;
        if (_.get(compo, 'rules.dynamic_zone_end') === true) dynamicZoneBeingProcessed = null;
      } else if (_.get(compo, 'rules.dynamic_zone_end') === true) {
        if (ans[dynamicZoneBeingProcessed]) {
          ans[dynamicZoneBeingProcessed].available_compos[domCompoId] = compo;
          // sort entries...
          ans[dynamicZoneBeingProcessed].available_compos = Object.fromEntries(Object.entries(ans[dynamicZoneBeingProcessed].available_compos).sort((a, b) => Math.sign(a[1]._idx - b[1]._idx)));
        }
        dynamicZoneBeingProcessed = null;
      } else if (dynamicZoneBeingProcessed) {
        if (ans[dynamicZoneBeingProcessed]) ans[dynamicZoneBeingProcessed].available_compos[domCompoId] = compo;
      } else ans[domCompoId] = compo;
    });

    /**
     * @param text
     * @param compo
     * @param supplyCompo
     * @returns {*}
     */
    const processText = (text, compo, supplyCompo = null) => {
      if (_.get(text, 'rules.optional') !== true) text.error = null;
      // Init text value
      text.value = null;
      if (supplyCompo) {
        // supplyCompo = edit
        _.each(_.get(supplyCompo, 'texts'), _text => {
          if (_text._id === text._id) {
            text.value = _.get(_text, 'content');
            text.filled_with_default = true;
          }
        });

        // if no value, take the default value
        text.value = _.get(text, 'value') ?? (_.get(text, 'rules.type') === 'boolean' ? _.get(text, 'rules.default_value') || false : _.get(text, 'rules.type') === 'select' ? _.get(text, 'rules.default_value') || text.rules.values[0].value : '');

        // for paragraphs, it needs double newline helptip
        if (_.get(text, 'rules.type') === 'paragraph') text.value = text.value.replace(/\n/g, '\n\n');
      } else {
        if (props.mode === 'config' || compo._dynamic_idx !== undefined && !compo._user || (props.mode === false && !props.video || props.mode === 'light' && !props.video || props.mode === 'preview' && !previewPrefill) && compo._prev !== undefined && compo._dynamic_idx === undefined) {
          text.value = (props.mode === 'preview' ? _.get(text, 'rules.preview_value') : null) || _.get(text, 'rules.default_value'); // h. if no given prefill of brand new video for static compo, or if available dynamic compo
        }
      }
      const pageType = StudioMiscellaneous.getPageType(props?.router?.asPath);
      if (pageType === 'create') {
        text.value = _.get(text, 'value') ? _.get(text, 'value') : StudioMiscellaneous.defaultValue(text?.rules ?? {}, true);
      } else {
        if (_.get(text, 'rules.type') === 'boolean') {
          text.value = Boolean(_.get(text, 'value'));
        } else {
          text.value = _.get(text, 'value') || _.get(text, 'value') === '' ? _.get(text, 'value') : StudioMiscellaneous.defaultValue(text?.rules ?? {}, true);
        }
      }

      // Tolerate bad boolean values... (should be true || false, but associated values would also be fine...)
      if (_.get(text, 'rules.type') === 'boolean' && !_.isBoolean(text.value) && !_.get(text, 'rules.default_value_by_variable')) {
        text.value = _.get({
          true: true,
          false: false
        }, _.get(_.invert(_.get(text, 'rules.bool') || {}), text.value));
        if (!text.value) text.value = false;
      }
      return text;
    };

    /**
     * @param file
     * @param compo
     * @param supplyCompo
     * @returns {*}
     */
    const processFile = (file, compo, supplyCompo = null) => {
      if (_.get(file, 'rules.optional') !== true) file.error = null;
      file.file = null;
      file.src = null;
      file.type = null;
      file.fetching = false;
      // We dont want to keep the crop if their is a default value by variable
      file.crop = StudioMiscellaneous.defaultValueByVariable(file?.rules ?? {}, false) ? null : _.get(file, 'crop') ?? null;
      file.muted = null;
      file.range = null;
      file._smart_croppr_companionable = false;
      if (supplyCompo) {
        _.each(_.get(supplyCompo, 'files') || [], _file => {
          if (_file._id === file._id) {
            const content = _file.content;
            const src = _.get(_file, 'content.src') || '';
            const type = _.get(_file, 'content.mime_type');
            const crop = _.get(_file, 'rules.crop');
            const range = _.get(_file, 'rules.range');
            const muted = _.get(_file, 'rules.muted');
            const meta = _.get(_file, 'meta');
            if (content) file.file = _file.content;
            if (src) file.src = absolute(src);
            if (type) file.type = type;
            if (crop) file.crop = crop;
            if (range) file.range = range;
            if (meta) file.meta = meta;
            if (muted === false || muted === true) file.muted = muted;
          }
        });
      } else if (props.mode === 'config' || compo._dynamic_idx !== undefined && !compo._user || (props.mode === false && !props.video || props.mode === 'light' && !props.video || props.mode === 'preview' && !previewPrefill) && compo._prev !== undefined && compo._dynamic_idx === undefined) {
        file.file = (props.mode === 'preview' ? _.get(file, 'rules.preview_value') : null) || StudioMiscellaneous.defaultValue(file?.rules ?? {}, false);
        file.src = absolute((props.mode === 'preview' ? _.get(file, 'rules.preview_value.src') : null) || _.get(file, 'rules.default_value.src') || _.get(file, 'rules.default_value_by_variable.src'));
        file.meta = (props.mode === 'preview' ? _.get(file, 'rules.preview_value.meta') : null) || _.get(file, 'rules.default_value.meta') || _.get(file, 'rules.default_value_by_variable.meta') || {};
      }
      if (file.src) {
        file.fetching = true;
        file._smart_croppr_companionable = !file.crop;
      }
      if (file.file) {
        file._smart_croppr_companionable = !!StudioMiscellaneous.defaultValue(file?.rules ?? {}, false);
      }
      return file;
    };

    /**
     * @param compo
     * @param supplyCompo (null: find it, object: take this supply, false: don't take any supply)
     * @returns {*}
     */
    const processCompo = (compo, supplyCompo = null) => {
      // if no supplyCompo is provided, we find it ourselves
      if (supplyCompo === null) _.each(dataSupplyCompos, _compo => {
        if (_compo._id === compo._id) supplyCompo = _compo;
      });
      _.forOwn(compo.chapters, chapter => {
        if (_.isObject(chapter.texts)) _.forOwn(chapter.texts, text => processText(text, compo, supplyCompo));
        if (_.isObject(chapter.files)) _.forOwn(chapter.files, file => processFile(file, compo, supplyCompo));
      });
      let newCompo = {
        ...compo,
        _set_id: _.get(supplyCompo, '_set_id') || uuidv4(),
        folded: getInStorage(`bridge.video.studio.${props.mode || 'basic'}.template/${props.template.id}.compo/${compo._id}&${compo._idx}.folded`) || false,
        renaming: false,
        description: _.get(supplyCompo, 'description') || _.get(compo, 'description') || null,
        thumbnail_previews: _.get(supplyCompo, 'thumbnail_previews') || {},
        _preview_refreshes: _.get(supplyCompo, '_preview_refreshes') || {}
      };
      _.forOwn(newCompo.chapters, chapter => {
        chapter._hash = StudioMiscellaneous.chapterHash(chapter, newCompo, ratio, theme);
        if (_.has(supplyCompo, `_preview_refreshes.${chapter._hash}`)) chapter._preview_refresh = {
          _definite: true,
          ..._.get(newCompo, `_preview_refreshes.${chapter._hash}`)
        };
      });
      if (_.get(compo, 'rules.optional') === true && dataSupplyCompos.length) {
        newCompo._deleted_by_user = false;

        // removed?
        let compoExists = false;
        _.each(dataSupplyCompos, _compo => {
          if (_compo._id === compo._id) compoExists = true;
        });
        if (!compoExists && !(
        // and when prefill is not only preset dynamic compos
        (!props.mode || props.mode === 'light') && !props.video && !givenPrefill && templatePrefill)) newCompo._deleted_by_user = true;
      }
      return newCompo;
    };
    //      perform it
    ans = _.zipObject(Object.keys(ans), _.map(Object.values(ans), item => {
      if (typeof item._id !== 'undefined') {
        // if item is a mere composition, not a dynamic zone
        return processCompo(item);
      }
      let userCompoIdx = 0;
      _.each(dataSupplyCompos, _compo => {
        _.each(_.get(item, 'available_compos') || [], compo => {
          if (_compo._id === compo._id && _compo._supplied !== true) {
            const domCompoId = StudioMiscellaneous.userCompoDomId(_compo._id, userCompoIdx);
            let userCompo = {
              ...eagerCloneDeep(compo),
              _idx: userCompoIdx,
              _dom_id: domCompoId,
              _available_compo_dom_id: compo._dom_id,
              _dynamic_zone_dom_id: item._dom_id,
              _user: true
            };
            userCompoIdx++;
            item.user_compos[userCompo._dom_id] = processCompo(userCompo, _compo);
            _compo._supplied = true; // mark composition as having already supplied user compositions
            return false; // break
          }
        });
      });

      // process available compos for later cop}ies when user will add custom compos
      _.each(_.get(item, 'available_compos') || [], compo => processCompo(compo, false));

      // prefill on?
      const prefill = getInStorage(`backOffice.template.dynamicZone.${item._idx}.prefill.on`);
      item.prefill = prefill ?? !!Object.keys(item.user_compos).length;
      setInStorage(`backOffice.template.dynamicZone.${item._idx}.prefill.on`, item.prefill);
      return item;
    }));
    return ans;
  };
  static userCompoDomId = (availableCompoId, userCompoIdx) => {
    return `compo/${xxh.h32(`compo:${availableCompoId}>userCompo:${userCompoIdx}`, 0xabcd).toString(16)}`;
  };
  static getPageType = routePath => {
    return routePath && routePath.match(/\edit/) ? 'edit' : routePath.match(/\prefillVideoId/) ? 'duplicate' : 'create';
  };
  static getRatioCode(resolution, ratio) {
    return resolution === '2560x1440' ? 'YOUTUBE_BANNER' : resolution === '1548x396' ? 'LINKEDIN_BANNER' : resolution === '851x315' ? 'FACEBOOK_BANNER' : resolution === '1080x1350' ? 'PORTRAIT_4_5' : ratio > 1.1 ? 'LANDSCAPE' : ratio < 0.9 ? 'PORTRAIT' : 'SQUARE';
  }
  static getRatioLabel(resolution, ratio) {
    const ratioCode = StudioMiscellaneous.getRatioCode(resolution, ratio);
    return tr(`front.studio.forms.snippet.ratio.${ratioCode.toLowerCase()}`, {}, 'bridge-video') || '';
  }
  static getRatioExampleLabel(ratioCode) {
    return tr(`front.studio.forms.snippet.ratio_example.${ratioCode.toLowerCase()}`, {}, 'bridge-video') || '';
  }
}
export default StudioMiscellaneous;