import * as _ from 'lodash';
import applyDeep from '@utils/lodash/applyDeep';
import isBasicObject from '@utils/lodash/isBasicObject';
import Immutable from './immutable';

export default class Template extends Immutable {
    static get(data, path, referenceKey = '_id', useIdsMap = true) {
        if (!data) return data;

        path = Template.dotted(path);
        if (!path) return data;

        if (path.charAt(0) !== '.') path = `.${path}`;

        // This needs an IDs map, and will improve performances.
        if (path.includes('..')) {
            const idsMap = _.get(data, '_.ids_map') || _.get(data, '_.idsMap') || [];
            const parts = _.compact(_.map(path.split('..'), e => e.split('.')[0]));
            let compo;
            let input;
            let typeCompo;
            let idxCompo;
            let idxChapter;
            let idxInput;
            let regex;
            if (parts.length === 1) {
                compo = _.get(idsMap, `${referenceKey === '_id' ? 'int' : 'ext'}.${parts[0]}`);
                if (compo) {
                    idxCompo = compo.idx;
                    typeCompo = compo.type;
                    regex = new RegExp(`(\\.?compositions|globals)?\\.\\.${parts[0]}`);
                    path = path = path.replace(regex, (match, g1) => {
                        if (g1 === 'compositions') {
                            return `compositions.${idxCompo}`;
                        }
                        if (g1 === 'globals') {
                            return `globals.${typeCompo}s.${idxCompo}`;
                        }
                        return `compositions.${idxCompo}`;
                    });
                }
            }
            if (parts.length === 2) {
                compo = _.get(idsMap, `${referenceKey === '_id' ? 'int' : 'ext'}.${parts[0]}`);
                if (compo) {
                    idxCompo = compo.idx;
                    input = _.get(compo, `inputs.${parts[1]}`);
                    if (input) {
                        idxInput = input.idx;
                        idxChapter = input.chapter_idx;
                        regex = new RegExp(`(\\.?compositions|globals)?\\.\\.${parts[0]}\\.\\.${parts[1]}`);
                        path = path = path.replace(regex, (match, g1) => {
                            if (g1 === 'compositions') {
                                return `compositions.${idxCompo}.chapters.${idxChapter}.${input.type}s.${idxInput}`;
                            }
                            if (g1 === 'globals') {
                                return `globals.${typeCompo}s${idxCompo}.chapters.${idxChapter}.${input.type}s.${idxInput}`;
                            }
                            return `compositions.${idxCompo}.chapters.${idxChapter}.${input.type}s.${idxInput}`;
                        });
                    }
                }
            }
        }

        let value;
        if (path.length > 1 && path.charAt(0) + path.charAt(1) === '..') {
            const exp = path.split('.');
            const id = exp[2];
            path = exp.length > 3 ? `.${exp.slice(3).join('.')}` : '';
            value = null;
            applyDeep(
                data,
                null,
                v => {
                    if (isBasicObject(v) && _.get(v, referenceKey) === id) value = Template.get(v, path, referenceKey, false);
                    return v;
                },
                v => {
                    return !(isBasicObject(v) && !!_.get(v, referenceKey));
                }
            );
        } else if (path.includes('..')) {
            path = path.replace(/^\./, '');
            const exp = path.split('.');
            const key = exp[0];
            const rest = path.replace(new RegExp(`^${key}`), '');
            value = _.get(data, key);
            if (rest) value = Template.get(value, rest, referenceKey, false);
        } else {
            if (path.charAt(0) === '.') path = path.substr(1);
            value = _.get(data, path);
        }

        return value;
    }

    static dotted(path = null) {
        if (!path) return null;

        return path.replace('/', '.');
    }
}
