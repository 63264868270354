import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import clsx from 'clsx';
type Props = {
  title: string;
  imagePath: string;
  isOpen: boolean;
  onClose: () => void;
};
const ModalImageInFullscreen = (props: Props) => {
  const {
    title,
    imagePath = '',
    isOpen = false,
    onClose
  } = props;
  return <Dialog open={isOpen} onClose={onClose} maxWidth='lg' fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={clsx('video:modal', '/fullscreen')}>
                <img src={imagePath} alt={tr('front.video.modal.fullscreen.title', {}, 'front_office-video')} className={clsx('video:modal', '/fullscreen', '/image')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{tr(`front.video.modal.fullscreen.buttons.cancel`, {}, 'front_office-video')}</Button>
            </DialogActions>
        </Dialog>;
};
export default ModalImageInFullscreen;